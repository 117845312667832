import React, { FunctionComponent } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { IMarkdownResult } from '../types/article'
import { IEdgesQuery } from '../types/queries'
import Head from '../components/head'
import Layout from '../components/layout'
import ScrollTop from '../components/scroll-top'

import '../styles/photos-grid.scss'

export interface IIndexPageProps {
  data: {
    allMarkdownRemark: IEdgesQuery<IMarkdownResult>
  }
}

const IndexPage: FunctionComponent<IIndexPageProps> = ({ data }) => {
  const pages = data.allMarkdownRemark.edges
    .map(e => e.node)

  return (
    <Layout
      className='index-page'
    >
      <Head
        title=''
      />

      <div
        className='photos-grid'
      >
        {pages.map(p => {
          return (
            <article
              key={p.id}
            >
              <Link
                to={p.fields.slug}
              >
                <Img
                  alt={p.frontmatter.title}
                  fluid={p.frontmatter.photo.childImageSharp.fluid}
                />
              </Link>
            </article>
          )
        })}
      </div>

      <ScrollTop />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPageQuery {
    allMarkdownRemark(sort: {fields: frontmatter___sort}) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            photo {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
                fixed(fit: COVER, height: 96, width: 96) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            title
          }
          id
        }
      }
    }
  }
`
