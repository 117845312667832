import React, { FunctionComponent } from 'react'

import icon from '../images/icons/expand_less-24px.svg'

const scrollTop: () => void = () => {
  document?.querySelector('main')?.focus()

  window?.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

const ScrollTop: FunctionComponent = () => {
  return (
    <button
      type='button'
      className='scroll-top'
      onClick={scrollTop}
    >
      <span>
        <img
          src={icon}
          alt='Scroll to top'
        />
      </span>
    </button>
  )
}

export default ScrollTop
